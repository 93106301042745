<template>
    <div class="store">
        <!-- 动态样式不生效.. 暂用定位图片代替 -->
        <!-- :style="[{'background-size':`url(${storeBackground}) no-repeat;`},{'background-size':'100% auto'}]" -->
        <div class="store_head">
            <img v-if="storeBackground" class="store_head_bj" :src="storeBackground" alt="" />
            <p class="store_head_title">商家详情</p>
            <div class="scan_code" @click="startRecognize" v-if="false">
                <img src="../../../static/images/scan_icon@2x.png" alt="" />
            </div>
            <div class="store_info">
                <div class="store_info_content">
                    <h4>{{ $i18n.locale == 'zh_HK' ? storeInfo.busName : storeInfo.busNameEn }}</h4>
                    <div class="store_status">
                        <!-- workStatus 营业状态 -->
                        <p>
                            <img src="../../../static/images/open_icon@2x.png" alt="" />
                            <span v-if="storeInfo.workStatus == 1">{{ $i18n.locale == 'zh_HK' ? '營業中' : 'In business' }}</span>
                            <span v-if="storeInfo.workStatus == 0">{{ $i18n.locale == 'zh_HK' ? '暫停營業' : 'closed' }}</span>
                        </p>
                        <div class="store_status_date">營業時間:{{ storeInfo.runTime }}:00-{{ storeInfo.stopTime }}:00</div>
                    </div>
                    <p class="store_address">商家地址:{{ $i18n.locale == 'zh_HK' ? storeInfo.busAddress : storeInfo.busAddressEn }}<img src="" alt="" /></p>
                </div>
                <div class="store_info_media">
                    <div class="store_pic">
                        <img :src="storeInfo.busAvatar" alt="" />
                    </div>
                    <div class="store_contact">
                        <img @click="callStore" src="../../../static/images/tel_icon@2x.png" alt="" />
                        <img @click="shareStore" src="../../../static/images/share_icon@2x.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
        <div class="store_goods">
            <el-tabs v-model="activeName">
                <el-tab-pane label="菜品" name="first">
                    <ul class="goods_list">
                        <li class="goods_item" v-for="(item, index) in goodsList" :key="index">
                            <div class="goods_item_pic">
                                <img :src="item.goodsPic" alt="" />
                            </div>
                            <div class="goods_item_info">
                                <div>
                                    <div class="item_goods_name">
                                        <h4>{{ item.goodsName }}</h4>
                                        <span>銷量 {{ item.sales || 0 }}</span>
                                    </div>
                                    <p class="goods_item_describe">{{ item.remark }}</p>
                                </div>
                                <div class="goods_item_price">HKD{{ item.goodsPrice }}</div>
                            </div>
                        </li>
                    </ul>
                </el-tab-pane>
                <el-tab-pane label="優惠" name="second">
                    <ul class="coupon_list">
                        <li v-for="(item, index) in couponList" :key="index">
                            <div class="coupon_item">
                                <div class="coupon_item_info">
                                    <h4>{{ item.title }}</h4>
                                    <p class="coupon_item_date">過期時間：{{ item.validEndDay }}</p>
                                    <div class="coupon_item_rule" @click="item.show = !item.show">
                                        <p>使用規則</p>
                                        <img src="../../../static/images/jiantou@2x.png" alt="" />
                                    </div>
                                </div>
                                <div class="coupon_item_operation">
                                    <!-- 满减  or  折扣 -->
                                    <div class="coupon_item_price" v-if="item.type == 0">HKD{{ item.couponPrice }}</div>
                                    <div class="coupon_item_price" v-else-if="item.type == 1">-{{ item.couponPrice }}%</div>
                                    <div class="coupon_item_describe">滿{{ item.useminPrice }}元可用</div>
                                    <!-- <div class="coupon_item_receive"> -->
                                    <!-- 为领取 -->
                                    <!-- <img v-if="true" @click="receive" src="../../../static/images/receive@2x.png" alt=""> -->
                                    <!-- 已领取 -->
                                    <!-- <img v-else src="../../../static/images/received@2x.png" alt=""> -->
                                    <!-- </div> -->
                                </div>
                            </div>
                            <div v-if="item.show" class="coupon_item_explain">
                                {{ item.ruleExplain }}
                            </div>
                        </li>
                    </ul>
                </el-tab-pane>
            </el-tabs>
        </div>
        <div class="popup_tips" v-if="isShowTips">領取成功</div>
        <div class="operation">
            <div class="operation_download" @click="download">下載iHK</div>
            <!-- <div v-if="isAndroid" class="operation_open" @click="open">打開iHK</div> -->
            <div v-if="isAndroid" class="operation_open" id="downloadButton">打開iHK</div>
        </div>
    </div>
</template>

<script>
import linkedme from '../../utils/linkedme.js';
export default {
    data() {
        return {
            activeName: 'first',
            isShowTips: false,
            storeInfo: {},
            goodsList: [],
            storeBackground: '',
            couponList: [],

            isIos: false,
            isAndroid: false,
            schemeUrl: '',
            downloadUrl: '',
            activeNames: '',
        };
    },
    inject: ['reload'],
    mounted() {
        let id = this.$route.query.id || 12;
        this.getData(id);
        linkedme({
            view: 'QQ',
        }).then((res) => {
            console.log(res);
        });
        this.phoneType();
    },
    methods: {
        download() {
            // window.location.href = this.downloadUrl
            this.$router.push({ name: 'Download' });
        },
        open() {
            this.navgatorshow = false;
            if (this.isBlackApp()) {
                this.$message({
                    message: '请用手机内置浏览器打开',
                    type: 'warning',
                });
                return;
            } else {
                this.noApp(); ////定时器
                this.jumpApp(this.schemeUrl);
            }
        },
        //判断机型
        phoneType() {
            let u = navigator.userAgent;
            let app = navigator.appVersion;
            let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
            let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            if (isAndroid) {
                this.isAndroid = true;
                this.schemeUrl = 'openfromweb://powerbank?id=' + this.$route.query.id; //    美团测试地址imeituan://
                this.downloadUrl = 'https://play.google.com/store/apps/details?id=com.mskj.powerbank'; ////Android下载地址
                this.createScriptTag();
                console.log('android');
            }
            if (isIOS) {
                this.isIos = true;
                this.schemeUrl = '你的iosSchemeUrl';
                this.downloadUrl = 'https://apps.apple.com/cn/app/ihk/id1563847976'; /////ios 下载地址
                console.log('ios');
            }
        },
        ////判断是否未内置浏览器
        isBlackApp() {
            var u = navigator.userAgent.toLowerCase();
            return /micromessenger/i.test(u) || u.indexOf('weibo') > -1 || u.indexOf('qq') > -1 || u.indexOf('mqqbrowser') > -1;
        },
        ////唤起app  参数为 app的Scheme 地址
        jumpApp(t) {
            if (this.isAndroid) {
                window.location.href = t;
            } else {
                try {
                    var e = navigator.userAgent.toLowerCase();
                    let n = e.match(/cpu iphone os (.*?) like mac os/);
                    if (((n = null !== n ? n[1].replace(/_/g, '.') : 0), parseInt(n) >= 9)) {
                        window.location.href = t;
                    } else {
                        var r = document.createElement('iframe');
                        (r.src = t), (r.style.display = 'none'), document.body.appendChild(r);
                    }
                } catch (e) {
                    window.location.href = t;
                }
            }
        },
        // 无响应或者没安装跳转下载
        noApp() {
            let t = Date.now();
            let r = this.downloadUrl;
            this.timer = setTimeout(function() {
                return Date.now() - t > 2200 ? (clearTimeout(this.timer), !1) : !document.webkitHidden && !document.hidden && void (location.href = r);
            }, 2000);
        },
        getData(id) {
            ////查询店铺信息
            this.$api
                .storeInfo(id)
                .then((res) => {
                    let img = res.result['busAvatar'].split(',')[0];
                    res.result['busAvatar'] = img.indexOf('http') != -1 ? img : this.$mediaURL + img;
                    let newImg =
                        res.result['busBackground'] && res.result['busBackground'].split(',')[0].indexOf('http') != -1
                            ? res.result['busBackground']
                            : this.$mediaURL + res.result['busBackground'];
                    // 营业时间
                    res.result.runTime = new Date(res.result.runTime).getHours() < 10 ? '0' + new Date(res.result.runTime).getHours() : new Date(res.result.runTime).getHours();
                    // 关闭时间
                    res.result.stopTime = new Date(res.result.stopTime).getHours() < 10 ? '0' + new Date(res.result.stopTime).getHours() : new Date(res.result.stopTime).getHours();
                    this.$nextTick(() => {
                        this.storeInfo = res.result;
                        this.storeBackground = res.result['busBackground'] && newImg;
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
            ////查询商品信息
            this.$api.getGoodsList(id).then((res) => {
                this.goodsList =
                    res.result &&
                    res.result.length > 0 &&
                    res.result.map((item) => {
                        let img = item.goodsImg.split(',')[0];
                        /////暂时取第一张图作为菜品封面
                        item.goodsPic = img.indexOf('http') != -1 ? img : this.$mediaURL + img;
                        return item;
                    });
            });
            ////查询优惠券
            this.$api
                .allCoupon(id)
                .then((res) => {
                    this.couponList =
                        res.result &&
                        res.result.length > 0 &&
                        res.result.map((item) => {
                            item.validEndDay = this.format(item.validEndDay);
                            item.show = false;
                            return item;
                        });
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        receive() {
            //打开app
            this.open();
            // this.isShowTips = true
            // setTimeout(() => {
            //     this.isShowTips = false
            // }, 1200);
        },
        callStore() {
            if (this.storeInfo.phone) {
                window.location.href = 'tel://' + this.storeInfo.phone;
            }
        },
        shareStore() {
            ////分享链接
            let _input = document.createElement('input'); // 直接构建input
            _input.value = location.href; // 设置内容
            document.body.appendChild(_input); // 添加临时实例
            _input.select(); // 选择实例内容
            document.execCommand('Copy'); // 执行复制
            this.$message({
                message: '已成功复制链接，快去分享吧',
                type: 'success',
            });
            document.body.removeChild(_input); // 删除临时实例
        },

        // startRecognize(){
        //     console.log(navigator)
        //     navigator.mediaDevices.getUserMedia({ audio: true, video: { facingMode: { exact: "environment" } } }).then(res => {
        //         console.log(res)
        //     }).catch(err => {
        //         console.log(err)
        //     })
        // },
        format(timestamp) {
            let date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
            let Y = date.getFullYear() + '-';
            let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
            let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
            let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
            let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            return Y + M + D + h + m + s;
        },
        createScriptTag() {
            //   let script1 = document.createElement("script");
            //   script1.type = "text/javascript";
            //   script1.src = "https://web.cdn.openinstall.io/openinstall.js";
            //   document.getElementsByTagName("head")[0].appendChild(script1);
            var obj = {
                id: this.$route.query.id,
            };
            let script2 = document.createElement('script');
            script2.type = 'text/javascript';
            // script2.text = `var data = OpenInstall.parseUrlParams();new OpenInstall({appKey: "jm230s",onready: function () {this.schemeWakeup();var m = this,button = document.getElementById("downloadButton");button.onclick = function () {m.wakeupOrInstall();return false;}} }, data);`;
            script2.text = `var data =${JSON.stringify(
                obj
            )};new OpenInstall({appKey: "jm230s",onready: function () {this.schemeWakeup();var m = this,button = document.getElementById("downloadButton");button.onclick = function () {m.wakeupOrInstall();return false;}} }, data);`;
            document.getElementsByTagName('head')[0].appendChild(script2);
        },
    },
};
</script>

<style lang="scss" scoped>
.store {
    background-size: 100% auto;
    position: relative;
    min-height: 100vh;
    padding-bottom: 100px;
    .store_head {
        height: 169px;
        position: relative;
        font-family: PingFangSC-Regular, PingFang SC;
        padding-top: 56px;
        background-size: 100% auto;
        .store_head_bj {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }
        .store_head_title {
            color: #ffffff;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            text-align: center;
            position: relative;
        }
        .scan_code {
            width: 24px;
            height: 24px;
            position: absolute;
            right: 11px;
            top: 56px;
            img {
                width: 24px;
                height: 24px;
            }
        }
        .store_info {
            width: 351px;
            min-height: 94px;
            background: #ffffff;
            box-shadow: 0px 1px 10px 0px rgba(0, 63, 68, 0.08);
            border-radius: 4px;
            padding: 12px 14px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            text-align: left;
            position: absolute;
            left: 50%;
            bottom: -20px;
            transform: translateX(-50%);
            .store_info_content {
                max-width: 70%;
                h4 {
                    font-size: 19px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 26px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .store_status {
                    display: flex;
                    align-items: center;
                    font-size: 10px;
                    padding: 8px 0;
                    p {
                        font-size: 10px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #de4530;
                        padding-right: 12px;
                        display: flex;
                        align-items: center;
                        img {
                            width: 9px;
                            height: 9px;
                            padding-right: 2px;
                        }
                    }
                    .store_status_date {
                        font-size: 10px;
                    }
                }
                .store_address {
                    font-size: 10px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #777777;
                    line-height: 14px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                }
            }
            .store_info_media {
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                .store_pic {
                    width: 48px;
                    height: 48px;
                    img {
                        width: 48px;
                        height: 48px;
                        border-radius: 4px;
                    }
                }
                .store_contact {
                    display: flex;
                    align-items: center;
                    padding-top: 2px;
                    img {
                        width: 24px;
                        height: 24px;
                    }
                    img:first-child {
                        margin-right: 6px;
                    }
                }
            }
        }
    }
    .store_goods {
        background: #ffffff;
        border-radius: 4px 4px 0px 0px;
        margin-top: -20px;
        padding: 50px 12px 0;

        .goods_list {
            .goods_item {
                display: flex;
                align-items: flex-start;
                padding: 12px 0;
                border-bottom: 1px solid #e6e6e6;
                .goods_item_pic {
                    width: 88px;
                    height: 88px;
                    background: #e6e6e6;
                    border-radius: 4px;
                    flex-shrink: 0;
                    img {
                        width: 88px;
                        height: 88px;
                        border-radius: 4px;
                    }
                }
                .goods_item_info {
                    padding-left: 8px;
                    width: calc(100% - 88px - 8px);
                    min-height: 88px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .item_goods_name {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        h4 {
                            font-size: 14px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 600;
                            color: #ff5e38;
                        }
                    }
                }
                .goods_item:first-child {
                    padding-top: 0;
                }
                .goods_item:last-child {
                    border-bottom: none;
                }
            }
            .coupon_list {
                /deep/ li {
                    margin-bottom: 10px;
                }
                .coupon_item {
                    height: 85px;
                    background: url('../../../static/images/coupon_bj@2x.png') no-repeat;
                    background-size: 100% auto;
                    padding: 8px 6px 8px 14px;
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    // border-radius: 4px;
                    .coupon_item_info {
                        h4 {
                            font-size: 18px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #333333;
                        }
                        span {
                            font-size: 10px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #aaaaaa;
                        }
                    }
                    .goods_item_describe {
                        // min-height: 48px;
                        font-size: 10px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #aaaaaa;
                        padding: 4px 0 0;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                        overflow: hidden;
                    }
                    .goods_item_price {
                        font-size: 14px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 600;
                        color: #ff5e38;
                    }
                }
                .coupon_item_explain {
                    padding: 8px 14px;
                }
                .coupon_item:last-child {
                    margin-bottom: 0;
                }
            }
            //
            /deep/ .el-tabs__nav-wrap::after {
                background: transparent;
            }
            .goods_item:first-child {
                padding-top: 0;
            }
            .goods_item:last-child {
                border-bottom: none;
            }
        }
        .coupon_list {
            li {
                margin-bottom: 10px;
            }
            .coupon_item {
                height: 85px;
                background: url('../../../static/images/coupon_bj@2x.png') no-repeat;
                background-size: 100% auto;
                padding: 8px 6px 8px 14px;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                // border-radius: 4px;
                .coupon_item_info {
                    h4 {
                        font-size: 18px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #333333;
                    }
                    .coupon_item_date {
                        font-size: 10px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        padding-top: 5px;
                    }
                    .coupon_item_rule {
                        padding-top: 6px;
                        font-size: 8px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #aeb2b9;
                        display: flex;
                        align-items: center;
                        img {
                            width: 13px;
                            height: 13px;
                        }
                    }
                }
                .coupon_item_operation {
                    text-align: center;
                    .coupon_item_price {
                        font-size: 20px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #ffffff;
                    }
                    .coupon_item_describe {
                        font-size: 10px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #ffffff;
                        // padding-bottom: 9px;
                    }
                    .coupon_item_receive {
                        img {
                            width: 52px;
                            height: 18px;
                        }
                    }
                }
            }
            .coupon_item_explain {
                padding: 0px 6px 8px 14px;
            }
            .coupon_item:last-child {
                margin-bottom: 0;
            }
        }
        //
        /deep/ .el-tabs__nav-wrap::after {
            background: transparent;
        }
        /deep/ .el-tabs__active-bar {
            background: linear-gradient(270deg, rgba(238, 28, 34, 0.3) 0%, #ee1c22 100%);
            border-radius: 1px;
        }
        /deep/ .el-tabs__item {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #777777;
        }
        /deep/ .el-tabs__item.is-active {
            color: #333333;
            font-weight: 600;
        }
    }
    .operation {
        width: calc(100% - 24px);
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        bottom: 43px;
        left: 12px;
        z-index: 332;
        display: flex;
        align-items: center;
        justify-content: center;
        div {
            width: 170px;
            height: 36px;
            line-height: 36px;
            border-radius: 18px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            text-align: center;
            border: 1px solid #de4530;
        }
        .operation_download {
            background: #de4530;
            color: #ffffff;
        }
        .operation_open {
            background: #ffffff;
            color: #de4530;
            margin-left: 10px;
        }
    }
    .popup_tips {
        width: 100px;
        height: 42px;
        line-height: 42px;
        text-align: center;
        border-radius: 8px;
        box-shadow: 0 8px 20px rgba(51, 51, 51, 0.08);
        position: fixed;
        bottom: 106px;
        left: 50%;
        transform: translateX(-50%);
        background: #ffffff;
        color: #333333;
        font-size: 13px;
        z-index: 333;
    }
}
</style>
